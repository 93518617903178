<template>
  <div>
    <HeaderPanel
      title="Info"
      :filter="filter"
      placeholder=""
      :hasDropdown="false"
      :hasFilter="false"
      :hasSearch="false"
    />
    <div class="title-tabs mt-3">INFO DETAIL</div>
    <div class="bg-white p-3">
      <div>
        <div>When register</div>
        <div>URL : {{ url }}/api/d/v1/customer/consent/cmgbrand</div>
        <div>Method : POST</div>
        <pre class="mt-3">
        {   
            "reference" : {{ var11 }} 
            "firstName" : {{ var2 }}, 
            "lastName" : {{ var3 }}, 
            "birthday" : {{ var4 }}, 
            "email" : {{ var5 }}, 
            "telephone" : {{ var10 }}, 
            "gender" : {{ var6 }}, 
            "province" : {{ var7 }}, 
            "socialId" : "", 
            "cmgBrandCode" : {{ var8 }}, 
            "channelId" : {{ var12 }}, 
            "PlantCode" : "", 
            "isConsentDirect" : {{ var9 }}'
            "consentTime" : null, 
        }
     
      </pre
        >
      </div>
      <div>
        <div>When Update Profile</div>
        <div>URL : {{ url }}/api/d/v1/customer/update</div>
        <div>Method : PUT</div>
        <pre class="mt-3">
        {   
            "id" : {{ var1 }} 
            "firstName" : {{ var2 }}, 
            "lastName" : {{ var3 }}, 
            "birthday" : {{ var4 }}, 
            "email" : {{ var5 }}, 
            "telephone" : {{ var10 }}, 
            "gender" : {{ var6 }}, 
            "province" : {{ var7 }}, 
            "socialId" : "", 
            "cmgBrandCode" : {{ var8 }}, 
            "isConsentDirect" : {{ var9 }}'
        }
     
      </pre
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      var1: "{{ pdpa id }}",
      var2: "{{ first name }}",
      var3: "{{ last name }}",
      var4: "{{ birthday }}",
      var5: "{{ email }}",
      var6: "{{ gender }}",
      var7: "{{ province }}",
      var8: "{{ brand code }}",
      var9: "{{ Consent status }}",
      var10: "{{ telephone }}",
      var11: "{{ member id }}",
      var12: "{{ channel id }}",
      id: this.$route.params.id,
      data: [],
    };
  },
  created() {
    // this.getData();
  },
  methods: {
    async getData() {
      const resp = await this.$Axios(
        `${process.env.VUE_APP_API}/Plugin/GetById/${this.id}`
      );
      this.data = resp.data.detail;
    },
  },
};
</script>

<style>
pre {
  font-size: 14px;
}
</style>
